import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import onsWerkImage from 'assets/images/pages/onswerk/1.jpg';

import Title from 'blocks/Title';
import Grid from 'blocks/Grid';
import ContactCta from 'blocks/ContactCta';

import Layout from 'components/Layout';
import Seo from 'components/Seo';
import Section from 'components/Section';
import ImageBlock from 'components/ImageBlock';
import Text from 'components/Text';
import Button from 'components/Button';

const OnsWerk = ({ data }) => {
  const items = data.allCases.nodes;

  return (
    <Layout>
      <Seo image={{ src: onsWerkImage }} />

      <Title
        image={{ src: onsWerkImage }}
        alt="Succesverhalen van tevreden webshop eigenaren"
        subtitle="Onze prestaties, jouw garantie"
        title="Succesverhalen van tevreden webshop eigenaren"
      >
        We kunnen zelf wel zeggen dat we mooie dingen maken, we laten liever ons
        werk en onze klanten voor zich spreken.
      </Title>

      <Section>
        <Grid width={2}>
          {items.map((item) => (
            <ImageBlock
              key={item._id}
              image={item.image}
              title={item.title}
              to={`/ons-werk/${item.slug}`}
            >
              <Text color="white" spacing="lg">
                {item.description}
              </Text>

              <Button as="span" color="white" hoverColor="secondary" outline>
                Bekijk {item.company} case
              </Button>
            </ImageBlock>
          ))}
        </Grid>
      </Section>

      <ContactCta />
    </Layout>
  );
};

OnsWerk.propTypes = {
  data: PropTypes.shape({
    allCases: PropTypes.shape({
      nodes: PropTypes.arrayOf(
        PropTypes.shape({
          _id: PropTypes.string.isRequired,
          slug: PropTypes.string.isRequired,
          title: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
          company: PropTypes.string.isRequired,
          image: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
        }),
      ).isRequired,
    }).isRequired,
  }).isRequired,
};

export const query = graphql`
  query cases {
    allCases(sort: {fields: [_created], order: DESC}) {
      nodes {
        _id
        slug
        title
        description
        company
        image {
          sharpImage: childImageSharp {
            fixed(width: 700, height: 450) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default OnsWerk;
